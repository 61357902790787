import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getShipmentInspection,
  updatePackageInspection,
  uploadFile,
} from "../services/instruments.service";
import { base64ToBlob } from "../utils/image";
import TextInputComponent from "./TextInput";
import { WebcamCapture } from "./WebcamComponent";
import FileInput from "./FileInput";
import PdfViewer from "./PdfViewer";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import Tab from "../components/Tab";
import { uploadURL } from "../services/url.service";
import VideoRecorder from "./VideoRecorder";
import ImageViewer from "./ImageViewer";
import HttpService from "../services/http.service";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { isMobile } from "../utils/screen";

const PackageInspection = ({
  id,
  setLoading = () => {},
  showToast = () => {},
  setpackageInfo = () => {},
  status = "",
}) => {
  const usertoken = localStorage.getItem("usertoken");
  const [capture, setCapture] = useState("");
  const [descStates, setDescStates] = useState({
    hasDesc: false,
    descSaved: false,
  });

  const [inspectionData, setInspectionData] = useState({
    capture: "",
    description: "",
    file_list: [],
    image_list: [],
    video_list: [],
  });

  const updateInspection = (
    inspectData = inspectionData,
    desc = false,
    fileT = "none",
    isDelete = false
  ) => {
    let tdata = !desc
      ? {
          image_capture: inspectData.capture,
          image_list: inspectData.image_list,
          video_list: inspectData.video_list,
          file_list: inspectData.file_list,
          description: inspectData.description,
        }
      : {
          description: inspectData.description,
        };
    setLoading(true);
    updatePackageInspection(id, tdata)
      .then(() => {
        if (desc) {
          showToast("Description Saved!", true);
          setDescStates({
            hasDesc:
              inspectData.description && inspectData.description !== ""
                ? true
                : false,
            descSaved:
              inspectData.description && inspectData.description !== ""
                ? true
                : false,
          });
        } else if (fileT === "image") {
          showToast(
            `Image ${isDelete ? "Deleted" : "Uploaded"} Successfully!`,
            true
          );
        } else if (fileT === "video") {
          showToast(
            `Video ${isDelete ? "Deleted" : "Uploaded"} Successfully!`,
            true
          );
        } else if (fileT === "file") {
          showToast(
            `File ${isDelete ? "Deleted" : "Uploaded"} Successfully!`,
            true
          );
        }
        setpackageInfo({
          image_capture: inspectData.capture,
          image_list: inspectData.image_list,
          video_list: inspectData.video_list,
          file_list: inspectData.file_list,
          description: inspectData.description,
        });
        setLoading(false);
      })
      .catch((e) => {
        showToast("Failed to upload file!", true);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCaptureImage = (b64img) => {
    setLoading(true);
    setCapture(b64img);
    const blob = base64ToBlob(b64img, "image/jpeg");
    const file = new File([blob], "pacakge_capture_" + id, {
      type: "image/jpeg",
    });

    uploadFile(file)
      .then((res) => {
        let pathuri = res?.data?.data[0];

        const isnpData = {
          ...inspectionData,
          image_list: [
            ...inspectionData?.image_list,
            { title: pathuri?.title || "", id: pathuri?.id },
          ],
        };
        setInspectionData(isnpData);
        updateInspection(isnpData, false, "image");
      })
      .catch((e) => {
        showToast("Failed to upload file!", true);
        setLoading(false);
      });
  };

  const handleUploads = (up_type, files) => {
    let flinks = [];
    let flen = files.length;
    setLoading(true);
    for (let f = 0; f < flen; f++) {
      uploadFile(files[f])
        .then((res) => {
          let pathuri = res?.data?.data[0];
          flinks.push({ title: pathuri?.title, id: pathuri?.id });
          if (f === flen - 1) {
            setInspectionData({
              ...inspectionData,
              [up_type + ""]: [...inspectionData?.[up_type + ""], ...flinks],
            });
          }
          updateInspection(
            {
              ...inspectionData,
              [up_type + ""]: [...inspectionData?.[up_type + ""], ...flinks],
            },
            false,
            up_type === "image_list"
              ? "image"
              : up_type === "video_list"
                ? "video"
                : "file"
          );
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getShipmentInspection(id)
      .then((res) => {
        setInspectionData({
          capture: res.data?.data?.image_capture || "",
          description: res.data?.data?.description || "",
          file_list: res.data?.data?.file_list || [],
          image_list: res.data?.data?.image_list || [],
          video_list: res.data?.data?.video_list || [],
        });
        setpackageInfo({
          capture: res.data?.data?.image_capture || "",
          description: res.data?.data?.description || "",
          file_list: res.data?.data?.file_list || [],
          image_list: res.data?.data?.image_list || [],
          video_list: res.data?.data?.video_list || [],
        });
        if (res.data?.data?.description && res.data?.data?.description !== "") {
          setDescStates({
            hasDesc: true,
            descSaved: true,
          });
        }
      })
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [blobData, setBlobData] = useState({ type: null, url: null });
  const [showPdf, setShowPdf] = useState(false);

  const [gettingViewData, setGettingViewData] = useState({
    status: false,
    id: "",
  });
  const viewFile = (url, id, type) => {
    setBlobData({ type: type, url: url });
    setShowPdf(true);
    setGettingViewData({
      status: false,
      id: id,
    });
  };

  const [tabId, setTabId] = useState("desc");
  const getTabId = (id) => {
    setTabId(id);
    if (inspectionData?.description.trim() !== "" && !descStates?.descSaved) {
      updateInspection(inspectionData, true);
    }
  };

  const handleFileDelete = (id, type) => {
    setLoading(true);
    HttpService.remove(uploadURL + id)
      .then((res) => {
        if (!res?.data?.data) {
          showToast(
            `Error deleting ${
              type === "image_list"
                ? "image"
                : type === "video_list"
                  ? "video"
                  : "file"
            }`,
            false
          );
          return;
        }
        const filtered = inspectionData?.[type + ""]?.filter(
          (item) => item?.id !== id
        );
        setInspectionData({
          ...inspectionData,
          [type + ""]: filtered,
        });

        updateInspection(
          {
            ...inspectionData,
            [type + ""]: filtered,
          },
          false,
          type === "image_list"
            ? "image"
            : type === "video_list"
              ? "video"
              : "file",
          true
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          height: "70px",
          zIndex: 9999,
          width: { sm: "100%", xs: "205%", position: "fixed", top: 0, left: 0 },
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ mt: 4 }}>
            <Box sx={{ backgroundColor: "#fff", p: 2, minHeight: "300px" }}>
              <Tab
                bars={[
                  { id: "desc", label: "Description *" },
                  { id: "images", label: "Images *" },
                  { id: "videos", label: "Videos *" },
                  { id: "files", label: "Files *" },
                  { id: "filler", label: "" },
                ]}
                getTabId={getTabId}
                current={tabId}
              />
              {tabId === "desc" && (
                <Box sx={{ mt: 3 }}>
                  <Typography
                    sx={{
                      color:
                        inspectionData?.description === "" ? "red" : "#334155",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please provide a description of this{" "}
                    <strong>package</strong>.
                  </Typography>
                  <Box sx={{ mb: 1 }}>
                    <TextInputComponent
                      placeholder="Enter a small description"
                      rows={8}
                      value={inspectionData.description}
                      handleChange={(e) => {
                        setInspectionData({
                          ...inspectionData,
                          description: e.target.value,
                        });

                        if (descStates?.descSaved) {
                          setDescStates((prev) => {
                            return { ...prev, descSaved: false };
                          });
                        }
                      }}
                      disabled={descStates?.descSaved}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Button
                      variant="contained"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "8px",
                        px: "10px",
                        textTransform: "none",
                        color: "#fff",
                        background: descStates?.descSaved
                          ? "#fb923c"
                          : descStates?.hasDesc
                            ? "#22c55e"
                            : "#0ea5e9",
                        ":hover": {
                          background: descStates?.descSaved
                            ? "#fb923c"
                            : descStates?.hasDesc
                              ? "#22c55e"
                              : "#0ea5e9",
                        },
                        fontFamily: "Open Sans",
                        height: "40px",
                      }}
                      onClick={() => {
                        if (descStates?.descSaved) {
                          setDescStates((prev) => {
                            return { ...prev, descSaved: false };
                          });
                        } else {
                          updateInspection(inspectionData, true);
                        }
                      }}
                    >
                      {descStates?.descSaved
                        ? "Edit Description"
                        : descStates?.hasDesc
                          ? "Update Description"
                          : "Add Description"}
                    </Button>
                  </Box>
                </Box>
              )}

              {tabId === "images" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        inspectionData?.image_list?.length > 0
                          ? "#334155"
                          : "red",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>capture/upload</strong> a picture of this
                    <strong> package</strong>.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      pb: 5,
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "start",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile() ? "100%" : "auto",
                        minWidth: "max-content",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile() ? "100%" : "400px",
                          maxWidth: "600px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <WebcamCapture
                            imagec={capture}
                            name={"Package"}
                            handleCaptureImage={(e) => {
                              handleCaptureImage(e);
                            }}
                            instruction={true}
                          />
                        </Box>

                        <Box sx={{ mt: 2, width: "100%" }}>
                          <FileInput
                            bgColor="#f2f2f2"
                            textColor="#000"
                            uploadlabel="Upload Images"
                            acc="image/*"
                            handleChange={(e) => {
                              handleUploads("image_list", e.target.files);
                            }}
                            idn={"upload-image-package"}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ width: "100%", overflowX: "auto" }}>
                      {inspectionData?.image_list?.length > 0 && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          {inspectionData?.image_list?.map((il, ilk) => (
                            <Box
                              key={(il?.id || il) + "" + ilk}
                              id={(il?.id || il) + "" + ilk}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  border: "1px solid #f1f5f9",
                                  borderRadius: "8px",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    viewFile(
                                      uploadURL + (il?.id || il) + "/public",
                                      (il?.id || il) + "" + ilk,
                                      "image"
                                    );
                                  }}
                                  sx={{
                                    height: "300px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <img
                                    src={`${uploadURL + (il?.id || il) + "/public"}?authorization=${usertoken}`}
                                    alt="package img"
                                    style={{
                                      width: "auto",
                                      height: "300px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: 1,
                                    alignItems: "start",
                                    justifyContent: "space-between",
                                    px: 2,
                                    borderTop: "1px solid #f1f5f9",
                                  }}
                                >
                                  {!isMobile() && (
                                    <IconButton
                                      onClick={() => {
                                        viewFile(
                                          uploadURL +
                                            (il?.id || il) +
                                            "/public",
                                          (il?.id || il) + "" + ilk,
                                          "image"
                                        );
                                      }}
                                    >
                                      {gettingViewData?.status &&
                                      gettingViewData?.id ===
                                        (il?.id || il) + "" + ilk ? (
                                        <CircularProgress
                                          size={18}
                                          sx={{ color: "#98A2B3" }}
                                        />
                                      ) : (
                                        <VisibilityOutlinedIcon
                                          sx={{
                                            fontSize: "18px",
                                            color: "#98A2B3",
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  )}

                                  {/* <a
                                    href={
                                      uploadURL + (il?.id || il) + "/public"
                                    }
                                    download
                                  >
                                    <IconButton>
                                      <FileDownloadOutlinedIcon
                                        sx={{
                                          fontSize: "18px",
                                          color: "#98A2B3",
                                        }}
                                      />
                                    </IconButton>
                                  </a> */}
                                  {status !== "delivered" &&
                                    status !== "enroute" && (
                                      <IconButton
                                        onClick={() => {
                                          handleFileDelete(
                                            il?.id || il,
                                            "image_list"
                                          );
                                        }}
                                      >
                                        <DeleteOutlineIcon
                                          sx={{
                                            fontSize: "18px",
                                            color: "#98A2B3",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}

              {tabId === "videos" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        inspectionData?.video_list?.length > 0
                          ? "#334155"
                          : "red",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>upload</strong> a video of this
                    <strong> package</strong>.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      pb: 5,
                      flexDirection: { xs: "column", md: "row" },
                      alignItems: "start",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: isMobile() ? "100%" : "auto",
                        minWidth: "max-content",
                      }}
                    >
                      <Box
                        sx={{
                          width: isMobile() ? "100%" : "400px",
                          maxWidth: "600px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <VideoRecorder
                            onUpdateInspection={(file, desc, filetype) => {
                              updateInspection(file, desc, filetype);
                            }}
                            setInspectionData={(val) => {
                              setInspectionData(val);
                            }}
                            allData={inspectionData}
                            id={id}
                            label={"Package"}
                          />
                        </Box>

                        <Box sx={{ mt: 2, width: "100%" }}>
                          <FileInput
                            bgColor="#f2f2f2"
                            textColor="#000"
                            acc="video/*"
                            uploadlabel="Upload Videos"
                            handleChange={(e) => {
                              handleUploads("video_list", e.target.files);
                            }}
                            idn={"upload-video-package"}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box sx={{ width: "100%", overflowX: "auto" }}>
                      {inspectionData?.video_list?.length > 0 && (
                        <Box sx={{ width: "100%", display: "flex" }}>
                          {inspectionData?.video_list?.map((il, ilk) => (
                            <Box
                              key={(il?.id || il) + "" + ilk}
                              id={(il?.id || il) + "" + ilk}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  border: "1px solid #f1f5f9",
                                  borderRadius: "8px",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "340px",
                                    overflow: "hidden",
                                  }}
                                >
                                  <video
                                    src={`${uploadURL + (il?.id || il) + "/public"}?authorization=${usertoken}`}
                                    controls
                                    style={{
                                      width: "100%",
                                      maxWidth: "600px",
                                      height: "auto",
                                    }}
                                  />

                                  {status !== "delivered" &&
                                    status !== "enroute" && (
                                      <IconButton
                                        onClick={() => {
                                          handleFileDelete(
                                            il?.id || il,
                                            "video_list"
                                          );
                                        }}
                                      >
                                        <DeleteOutlineIcon
                                          sx={{
                                            fontSize: "18px",
                                            color: "#98A2B3",
                                          }}
                                        />
                                      </IconButton>
                                    )}
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              {tabId === "files" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 3,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#334155",
                      fontFamily: "Open Sans",
                      fontSize: "14px",
                      mb: 3,
                    }}
                  >
                    * Please <strong>upload</strong> a file for this{" "}
                    <strong> package</strong>.
                  </Typography>
                  <Box>
                    {inspectionData?.file_list?.length > 0 && (
                      <Box
                        sx={{
                          mb: 5,
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                        }}
                      >
                        {inspectionData?.file_list?.map((ifl, iflk) => (
                          <Box
                            key={iflk}
                            id={iflk}
                            sx={{
                              mb: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              p: 1,
                            }}
                          >
                            <Box
                              onClick={() => {
                                viewFile(
                                  uploadURL + (ifl?.id || ifl) + "/public",
                                  iflk,
                                  "file"
                                );
                              }}
                              sx={{
                                maxWidth: "350px",
                                wordBreak: "break-all",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Open Sans",
                                  fontSize: "12px",
                                }}
                              >
                                {ifl?.title || ifl}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                alignItems: "start",
                                ml: 2,
                              }}
                            >
                              {!isMobile() && (
                                <IconButton
                                  onClick={() => {
                                    viewFile(
                                      uploadURL + (ifl?.id || ifl) + "/public",
                                      iflk,
                                      "file"
                                    );
                                  }}
                                >
                                  {gettingViewData?.status &&
                                  gettingViewData?.id === iflk ? (
                                    <CircularProgress
                                      size={18}
                                      sx={{ color: "#98A2B3" }}
                                    />
                                  ) : (
                                    <VisibilityOutlinedIcon
                                      sx={{
                                        fontSize: "18px",
                                        color: "#98A2B3",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              )}

                              {/* <a
                                href={uploadURL + (ifl?.id || ifl) + "/public"}
                                download
                              >
                                <IconButton>
                                  <FileDownloadOutlinedIcon
                                    sx={{ fontSize: "18px", color: "#98A2B3" }}
                                  />
                                </IconButton>
                              </a> */}
                              {status !== "delivered" &&
                                status !== "enroute" && (
                                  <IconButton
                                    onClick={() => {
                                      handleFileDelete(
                                        ifl?.id || ifl,
                                        "file_list"
                                      );
                                    }}
                                  >
                                    <DeleteOutlineIcon
                                      sx={{
                                        fontSize: "18px",
                                        color: "#98A2B3",
                                      }}
                                    />
                                  </IconButton>
                                )}
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}

                    <Box sx={{ width: "300px" }}>
                      <FileInput
                        bgColor="#f2f2f2"
                        textColor="#000"
                        uploadlabel="Upload Files"
                        handleChange={(e) => {
                          handleUploads("file_list", e.target.files);
                        }}
                        idn={"upload-file-package"}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        maxWidth={"md"}
        open={showPdf}
        onClose={() => {
          setShowPdf(false);
        }}
      >
        <DialogContent>
          <Box>
            {blobData?.type === "file" ? (
              <PdfViewer url={blobData?.url} />
            ) : blobData.type === "image" ? (
              <ImageViewer url={blobData.url} />
            ) : (
              <Box>
                <pre>{JSON.stringify(blobData.data)}</pre>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default PackageInspection;
