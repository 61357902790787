import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import HttpService from "../services/http.service";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { drxnaUrl } from "../services/url.service";
import AddIcon from "@mui/icons-material/Add";
import { searchShipment } from "../services/instruments.service";

import ListFormatIcon from "@mui/icons-material/FormatListBulleted";
import GridFormatIcon from "@mui/icons-material/WindowOutlined";
import { isMobile } from "../utils/screen";

const ShipmentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [shipments, setShipments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [addSelected, setAddSelected] = useState(!!location.state?.selected);
  const [page, setPage] = useState(1);

  const [shipmentStatus, setShipmentStatus] = useState("&");

  const handleShipmentStatusChange = (event) => {
    setShipmentStatus(event.target.value);
    setPage(1);
  };

  const getAllShipments = (page) => {
    setLoading(true);

    const url = addSelected
      ? `${drxnaUrl}shipment/?page=${page}&limit_per_page=10&status=created`
      : `${drxnaUrl}shipment/?page=${page}&limit_per_page=10${shipmentStatus}`;
    HttpService.get(url, {})
      .then((res) => {
        setShipments(res?.data?.data || []);
        setTotalPages(res?.data?.metadata?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllShipments(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, shipmentStatus]);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState({
    status: false,
    id: "",
    name: "",
  });
  const [shipmentName, setShipmentName] = useState("");
  const [prefix, setPrefix] = useState("");
  const [gridMode, setGridMode] = useState(isMobile());
  function generateShipmentName() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    setPrefix(`SH-${day}-${month}-${year}-`);
  }
  const [showError, setShowError] = useState(false);
  const creatShipment = () => {
    if (shipmentName?.trim() === "") {
      setShowError(true);
      return;
    }
    setShowError(false);
    setLoading(true);
    HttpService.post(`${drxnaUrl}shipment/`, {
      name: prefix + shipmentName + "",
    })
      .then((res) => {
        navigate("/shipment/" + res?.data?.data?._id);
        setOpenCreateModal(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cancelShipment = (id) => {
    setLoading(true);
    HttpService.remove(`${drxnaUrl}shipment/${id}`)
      .then(() => {
        setShipments((prevMessages) =>
          prevMessages?.map((shipment) =>
            shipment._id === id
              ? { ...shipment, shipment_status: "cancelled" }
              : shipment
          )
        );
        setOpenWarningModal({ status: false, id: "", name: "" });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [searchQuery, setSearchQuery] = useState(null);
  const [searchPage, setSearchPage] = useState(1);
  const [totalSerchPages, setTotalSearchPages] = useState(0);

  const handleSearch = () => {
    setLoading(true);
    searchShipment(searchPage, 10, searchQuery, addSelected, shipmentStatus)
      .then((res) => {
        setShipments(res?.data?.data || []);
        setTotalSearchPages(res?.data?.metadata?.pagination?.total_pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleShipmentSelection = (id) => {
    if (addSelected) {
      let selected_s = location.state?.selected;
      setLoading(true);
      HttpService.put(`${drxnaUrl}shipment/${id}/sample/?ignore_failure=true`, {
        sample_id_list: [selected_s?.["Sample Id"]],
      })
        .then((res) => {
          if (res?.data?.data) {
            setLoading(false);
            navigate(`/shipment/${id}`);
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      navigate(`/shipment/${id}`);
    }
  };

  useEffect(() => {
    if (searchQuery === "") {
      setLoading(true);
      setSearchQuery(null);
      setSearchPage(1);
      const url = addSelected
        ? `${drxnaUrl}shipment/?page=${page}&limit_per_page=10&status=created`
        : `${drxnaUrl}shipment/?page=${page}&limit_per_page=10${shipmentStatus}`;
      HttpService.get(url, {})
        .then((res) => {
          setShipments(res?.data?.data || []);
          setTotalPages(res?.data?.metadata?.pagination?.total_pages);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
      return;
    }
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      if (searchQuery !== "" && searchQuery) {
        handleSearch();
      }
    }, 500);

    setDebounceTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery !== "" && searchQuery) {
      handleSearch(searchPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPage]);

  return (
    <Box sx={{ height: "calc(100vh - 70px - 80px)", position: "relative" }}>
      <Box sx={{ position: "fixed", top: "70px", width: "100%", zIndex: 999 }}>
        {loading && (
          <LinearProgress
            sx={{
              backgroundColor: "#f1f5f9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#ff6412ad",
              },
            }}
          />
        )}
      </Box>

      <Box sx={{ px: { xs: 1, sm: 2, md: 5 }, py: { xs: 2, md: 4 } }}>
        {addSelected && (
          <Box
            elevation={3}
            sx={{
              mb: 3,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "18px", sm: "24px" },
                fontWeight: "600",
                color: "#0f172a",
                fontFamily: "Open Sans",
              }}
            >
              Select a shipment to add into.
            </Typography>

            <Button
              size="medium"
              variant="contained"
              color="secondary"
              sx={{
                textTransform: "none",
                fontSize: "14px",
                ml: 2,
                py: 1,
                px: 2,
                borderRadius: "8px",
                backgroundColor: "#1e293b",
                "&:hover": {
                  backgroundColor: "#334155",
                },
              }}
              onClick={(e) => {
                setAddSelected(false);
                navigate(-1);
              }}
            >
              Cancel Selection
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: "100%",
            justifyContent: "space-between",
            mb: { xs: 1, sm: 2 },
            gap: 2,
          }}
        >
          {!addSelected && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", sm: "22px" },
                    fontWeight: "600",
                    color: "#1e293b",
                    fontFamily: "Open Sans",
                  }}
                >
                  Shipments.
                </Typography>
              </Box>

              <Button
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                  fontFamily: "Open Sans",
                  borderRadius: "8px",
                  height: "45px",
                  px: 3,
                  minWidth: "max-content",
                  display: { sm: "none" },
                }}
                onClick={() => {
                  generateShipmentName();
                  setOpenCreateModal(true);
                }}
                variant="contained"
              >
                New Shipment
              </Button>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              alignItems: "center",
              flexDirection: { xs: "row", sm: "row" },
              gap: 2,
            }}
          >
            {!addSelected && (
              <FormControl fullWidth>
                <InputLabel id="simple-select-label">Status</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  value={shipmentStatus}
                  label="Options"
                  onChange={handleShipmentStatusChange}
                  sx={{
                    width: "100%",
                    minWidth: "200px",
                    maxWidth: "500px",
                    height: "45px",
                  }}
                >
                  <MenuItem value={"&"}>All</MenuItem>
                  <MenuItem value={"&status=created"}>Created</MenuItem>
                  <MenuItem value={"&status=enroute"}>Enroute</MenuItem>
                  <MenuItem value={"&status=delivered"}>Delivered</MenuItem>
                  <MenuItem value={"&status=cancelled"}>Cancelled</MenuItem>
                </Select>
              </FormControl>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                border: "1px solid #cbd5e1",
                borderRadius: "8px",
                width: "100%",
                minWidth: { xs: "300px" },
                height: "45px",
              }}
            >
              <Input
                value={searchQuery || ""}
                disableUnderline
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                variant="contained"
                placeholder="Search"
                sx={{ px: 1, width: "100%", height: "100%" }}
              />
            </Box>

            {!addSelected && (
              <Button
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                  fontFamily: "Open Sans",
                  borderRadius: "8px",
                  height: "45px",
                  px: 3,
                  minWidth: "max-content",
                  display: { xs: "none", sm: "flex" },
                }}
                onClick={() => {
                  generateShipmentName();
                  setOpenCreateModal(true);
                }}
                variant="contained"
              >
                New Shipment
              </Button>
            )}
          </Box>
        </Box>

        {gridMode ? (
          <Box sx={{ p: 1 }}>
            <Grid container spacing={3}>
              {shipments &&
                shipments.map((shipment, index) => (
                  <Grid item xs={12} md={4} xl={3} key={index}>
                    <Card
                      sx={{
                        cursor: "pointer",
                        boxShadow: "0px 1px 14px rgba(0, 0, 0, 0.1)",
                        ":hover": {
                          boxShadow: "0px 1px 14px rgba(0, 0, 0, 0.15)",
                        },
                      }}
                      onClick={() => {
                        handleShipmentSelection(shipment?._id);
                      }}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            mb: 1,
                            display: "flex",
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={"600"}
                            sx={{
                              fontFamily: "Open Sans",
                              wordBreak: "break-all",
                            }}
                          >
                            {shipment?.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            mb: 1,
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Open Sans",
                            }}
                          >
                            Shipment Status:
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Open Sans",
                              color:
                                shipment?.shipment_status === "cancelled"
                                  ? "red"
                                  : shipment?.shipment_status === "delivered"
                                    ? "#22c55e"
                                    : shipment?.shipment_status === "enroute"
                                      ? "#0ea5e9"
                                      : "#000",
                            }}
                          >
                            {shipment?.shipment_status}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            mb: 1,
                            display: "flex",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Open Sans",
                              minWidth: "max-content",
                            }}
                          >
                            Shipment ID:
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight={"600"}
                            sx={{
                              fontFamily: "Open Sans",
                              ml: 1,
                              mt: 0.5,
                              wordBreak: "break-all",
                            }}
                          >
                            {shipment?._id}
                          </Typography>
                        </Box>
                        {!addSelected && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "end",
                              height: "30px",
                              mt: 2,
                              gap: 1,
                            }}
                          >
                            {shipment?.shipment_status !== "cancelled" &&
                              shipment?.shipment_status !== "delivered" && (
                                <Button
                                  sx={{
                                    textTransform: "none",
                                    minWidth: "max-content",
                                  }}
                                  variant="outlined"
                                  color="warning"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenWarningModal({
                                      status: true,
                                      id: shipment?._id,
                                      name: shipment?.name,
                                    });
                                  }}
                                >
                                  Cancel Shipment
                                </Button>
                              )}
                            {(shipment?.shipment_status === "delivered" ||
                              shipment?.shipment_status === "enroute") && (
                              <Button
                                variant="outlined"
                                color="success"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/track/${shipment?._id}`);
                                }}
                                sx={{ textTransform: "none" }}
                              >
                                Track
                              </Button>
                            )}
                          </Box>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>
        ) : (
          shipments?.length !== 0 && (
            <TableContainer
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                mb: 2,
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    borderBottom: "1px solid #e2e8f0",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    background: "#fff",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        borderRight: "1px solid #e2e8f0",
                        minWidth: "max-content",
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"600"}
                        sx={{
                          cursor: "pointer",
                          fontFamily: "Open Sans",
                          minWidth: "max-content",
                        }}
                      >
                        Shipment Name
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{
                        borderRight: "1px solid #e2e8f0",
                        minWidth: "max-content",
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight={"600"}
                        sx={{
                          cursor: "pointer",
                          fontFamily: "Open Sans",
                          minWidth: "max-content",
                        }}
                      >
                        Shipment Status
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                      <Typography
                        variant="body2"
                        fontWeight={"600"}
                        sx={{ cursor: "pointer", fontFamily: "Open Sans" }}
                      >
                        Shipment ID
                      </Typography>
                    </TableCell>

                    {!addSelected && (
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          minWidth: "max-content",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            fontFamily: "Open Sans",
                            minWidth: "max-content",
                          }}
                        >
                          Actions
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shipments?.map((shipment, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor:
                          index % 2 === 0 ? "#FFFFFF" : "#fff3eb",
                        ":hover": {
                          backgroundColor: "#e0f2fe",
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleShipmentSelection(shipment?._id);
                      }}
                    >
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                          maxWidth: "400px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: "400px",
                            overflowX: "auto",
                            wordBreak: "break-all",
                          }}
                        >
                          <Typography
                            sx={{
                              minWidth: "max-content",
                              fontFamily: "Open Sans",
                            }}
                          >
                            {shipment?.name}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          py: 0,
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          <Typography
                            sx={{
                              fontFamily: "Open Sans",
                              color:
                                shipment?.shipment_status === "cancelled"
                                  ? "red"
                                  : shipment?.shipment_status === "delivered"
                                    ? "#22c55e"
                                    : shipment?.shipment_status === "enroute"
                                      ? "#0ea5e9"
                                      : "#000",
                            }}
                          >
                            {shipment?.shipment_status}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          borderBottom: "none",
                          px: "16px",
                        }}
                      >
                        <Box sx={{}}>
                          <Typography sx={{ fontFamily: "Open Sans" }}>
                            {shipment?._id}
                          </Typography>
                        </Box>
                      </TableCell>
                      {!addSelected && (
                        <TableCell
                          sx={{
                            borderRight: "1px solid #e2e8f0",
                            borderBottom: "none",
                            px: "16px",
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {(shipment?.shipment_status === "delivered" ||
                              shipment?.shipment_status === "enroute") && (
                              <Button
                                variant="outlined"
                                color="success"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/track/${shipment?._id}`);
                                }}
                                sx={{ textTransform: "none" }}
                              >
                                Track
                              </Button>
                            )}

                            {shipment?.shipment_status !== "cancelled" &&
                              shipment?.shipment_status !== "delivered" && (
                                <Button
                                  sx={{
                                    textTransform: "none",
                                    minWidth: "max-content",
                                  }}
                                  variant="outlined"
                                  color="warning"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenWarningModal({
                                      status: true,
                                      id: shipment?._id,
                                      name: shipment?.name,
                                    });
                                  }}
                                >
                                  Cancel Shipment
                                </Button>
                              )}
                          </Box>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          {totalPages > 1 && !searchQuery && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={(e, p) => {
                setPage(p);
              }}
              variant="outlined"
              shape="rounded"
            />
          )}
          {totalSerchPages > 1 && searchQuery && (
            <Pagination
              count={totalSerchPages}
              page={searchPage}
              onChange={(e, p) => {
                setSearchPage(p);
              }}
              variant="outlined"
              shape="rounded"
            />
          )}
          {!loading && (
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 1.5,
                alignItems: "end",
              }}
            >
              <ListFormatIcon
                onClick={() => {
                  setGridMode(false);
                  localStorage.setItem("shipmentView", "list");
                }}
                sx={{
                  color: gridMode ? "#666666" : "#ff6412",
                  fontSize: "28px",
                  cursor: "pointer",
                }}
              />

              <GridFormatIcon
                onClick={() => {
                  setGridMode(true);
                  localStorage.setItem("shipmentView", "grid");
                }}
                sx={{
                  color: gridMode ? "#ff6412" : "#666666",
                  fontSize: "28px",
                  cursor: "pointer",
                }}
              />
            </Box>
          )}
        </Box>

        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openCreateModal}
          onClose={() => {
            setOpenCreateModal(false);
          }}
        >
          <DialogTitle>
            <Typography sx={{ fontFamily: "Open Sans" }}>
              Add Package Name
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Open Sans", minWidth: "max-content" }}
              >
                {prefix}
              </Typography>

              <Input
                value={shipmentName}
                sx={{ width: "100%" }}
                error={showError}
                onChange={(e) => {
                  setShipmentName(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                onClick={creatShipment}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                  fontFamily: "Open Sans",
                  borderRadius: "8px",
                }}
                variant="contained"
              >
                Create
              </Button>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openWarningModal?.status}
          onClose={() => {
            setOpenWarningModal({ status: false, id: "", name: "" });
          }}
        >
          <DialogTitle>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              Are you sure you want to cancel the shipment "
              <strong>{openWarningModal?.name}</strong>"?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontFamily: "Open Sans", textAlign: "center" }}>
              Click '<strong>Continue</strong>' to proceed with the cancellation
              or '<strong>Abort</strong>' to keep the shipment active.
            </Typography>
            <Box sx={{ display: "flex", gap: 3, mt: 3 }}>
              <Button
                onClick={() => {
                  setOpenWarningModal({ status: false, id: "", name: "" });
                }}
                variant="outlined"
                color="success"
                sx={{
                  background: "#fff",
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                }}
              >
                Abort
              </Button>
              <Button
                onClick={() => {
                  cancelShipment(openWarningModal?.id);
                }}
                color="warning"
                sx={{
                  width: "100%",
                  textTransform: "none",
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                }}
                variant="contained"
              >
                Continue
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ShipmentPage;
