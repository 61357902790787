import HttpService from "./http.service";
import {
  uploadURL,
  topicURL,
  userMediaBaseURL,
  courseContentURL,
  shipmentURL,
  xmiURL,
  samplesURL,
} from "./url.service";

export const uploadFile = (file) => {
  let fd = new FormData();
  fd.append("files", file);

  return HttpService.post(uploadURL, fd);
};

export const uploadMedia = (updata) => {
  const fd = new FormData();
  fd.append("files", updata);
  return HttpService.multi_part_post(userMediaBaseURL, fd);
};

export const getShipmentInspection = (id) => {
  return HttpService.get(`${shipmentURL}/${id}/inspection/outbound`, {});
};

export const searchShipment = (
  page,
  per_page,
  query,
  filter,
  shipmentStatus
) => {
  const url = filter
    ? `${shipmentURL}?page=${page}&limit_per_page=${per_page}&q=${query}&status=created`
    : `${shipmentURL}?page=${page}&limit_per_page=${per_page}&q=${query}${shipmentStatus}`;

  return HttpService.get(url);
};

export const getSampleInspection = (shipmentId, sampleId) => {
  return HttpService.get(
    `${shipmentURL}/${shipmentId}/sample/${sampleId}/inspection/outbound`,
    {}
  );
};

export const updateTopicContent = (top_id, cont_id, cont_data) => {
  return HttpService.put(
    courseContentURL + "/" + cont_id + "?topic_id=" + top_id,
    cont_data
  );
};

export const createIndependantTopic = (topicdata) => {
  return HttpService.post(topicURL + "/", topicdata);
};

export const createTopicContent = (top_id, content) => {
  return HttpService.post(topicURL + "/" + top_id + "/content", content);
};

export const updatePackageInspection = (id, data) => {
  return HttpService.put(`${shipmentURL}/${id}/inspection/outbound`, data);
};

export const updateSampleInspection = (id, sid, data) => {
  return HttpService.put(
    `${shipmentURL}/${id}/sample/${sid}/inspection/outbound`,
    data
  );
};
export const updateSample = (id, data) => {
  return HttpService.put(samplesURL + id, data);
};
export const confirmShipmentStatus = (id, status) => {
  return HttpService.put(
    `${shipmentURL}/${id}/inspection/outbound/confirm?final_status=${status}`,
    {}
  );
};

export const confirmSampleStatus = (sh_id, s_id, status) => {
  return HttpService.put(
    `${shipmentURL}/${sh_id}/sample/${s_id}/inspection/outbound/confirm?final_status=${status}`,
    {}
  );
};

export const fetchSampleById = (id) => {
  const org = localStorage.getItem("orgid");
  const user = localStorage.getItem("uid");

  return HttpService.get(
    `${xmiURL}get_sample?Sample Id=` +
      id +
      "&&user_id=" +
      user +
      "&&org_id=" +
      org,
    {}
  );
};

export const fetchSampleInspection = (shipment_id, sample_id) => {
  return HttpService.get(
    shipmentURL +
      "/" +
      shipment_id +
      "/sample/" +
      sample_id +
      "/inspection/inbound",
    {}
  );
};

export const fetchSampleInspectionOut = (shipment_id, sample_id) => {
  return HttpService.get(
    shipmentURL +
      "/" +
      shipment_id +
      "/sample/" +
      sample_id +
      "/inspection/outbound",
    {}
  );
};

export const fetchShipmentInspection = (shipment_id) => {
  return HttpService.get(
    shipmentURL + "/" + shipment_id + "/inspection/inbound",
    {}
  );
};

export const fetchShipmentInspectionOut = (shipment_id) => {
  return HttpService.get(
    shipmentURL + "/" + shipment_id + "/inspection/outbound",
    {}
  );
};
